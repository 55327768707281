import { LinkTypes } from 'fitify-ui-landing/src/@types'

const navigationData: LinkTypes.Navigation[] = [
  {
    link: '/#how-it-works',
    type: 'link',
    caption: 'hc_landing_section_title_how_it_works',
    isHiddenOnMobile: false,
  },
  {
    link: '/trainers',
    type: 'link',
    caption: 'hc_landing_section_title_trainers',
    isHiddenOnMobile: false,
  },
  {
    link: '/business',
    type: 'link',
    caption: 'hc_landing_work_section_title_for_business',
    isHiddenOnMobile: false,
  },
  {
    link: process.env.NEXT_PUBLIC_BLOG_URL,
    type: 'link',
    caption: 'Blog',
    link_type: '_blank',
    isHiddenOnMobile: false,
  },
  {
    link: '/#frequently-asked-questions',
    type: 'link',
    caption: 'hc_landing_section_title_faqs',
    isHiddenOnMobile: false,
  },
]

export { navigationData }
