import {
  AppstoreBadgeOutlineIcon,
  GooglePlayBadgeOutlineIcon,
} from 'fitify-ui/src/Icon/custom'
import {
  FitifyHcLogoPictogramIcon,
  FitifyLogoTemporaryIcon,
} from 'fitify-ui/src/Icon/logo'
import Navigation from 'fitify-ui-landing/src/components/Navigation/Navigation'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import { OpenGraph } from 'next-seo/lib/types'

// STATIC
import { footerCopy, footerNavigation, footerSocial } from '@/data/footer'
import { navigationData } from '@/data/navbar'

const Footer = dynamic(
  () => import('fitify-ui-landing/src/components/blocks/Footer/Footer')
)
const Newsletter = dynamic(
  () => import('fitify-ui-landing/src/components/blocks/Newsletter/Newsletter')
)

const Layout = ({
  seo,
  shouldHideNewsletter,
  children,
}: {
  seo: { title?: string; description?: string; openGraph?: OpenGraph }
  shouldHideNewsletter?: boolean
  children: React.ReactNode
}) => {
  const { t } = useTranslation()

  return (
    <>
      <NextSeo
        title={seo.title}
        description={seo.description}
        openGraph={seo.openGraph}
      />
      <Navigation
        items={navigationData}
        options={{ mobileEnabled: true }}
        logo={<FitifyLogoTemporaryIcon />}
      />

      <div>
        {children}

        {!shouldHideNewsletter && (
          <Newsletter
            blockText="hc_landing_emailing_block_text"
            emailPlaceholderText="hc_landing_emailing_block_your_email"
            submitButtonText="hc_landing_emailing_block_button"
            successMessage="hc_landing_newsletter_success_messages"
            errorMessage="hc_landing_newsletter_error"
          />
        )}

        <Footer
          companyName={`${t('landing_contact_us_company_name')}`}
          copyLinks={footerCopy}
          socialLinks={footerSocial}
          navigationLinks={footerNavigation}
          logo={<FitifyHcLogoPictogramIcon />}
          storeLinks={[
            {
              name: 'App Store',
              icon: <AppstoreBadgeOutlineIcon />,
              link: 'https://apps.apple.com/us/app/fitify-1-on-1-personal-trainer/id1248388898',
            },
            {
              name: 'Google Play',
              icon: <GooglePlayBadgeOutlineIcon />,
              link: 'https://play.google.com/store/apps/details?id=com.fitifyworkouts.personaltrainer',
            },
          ]}
        />
      </div>
    </>
  )
}

export default Layout
